/* Adelle */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@font-face {
  font-family: "adelle-light";
  src: url(./fonts/adelle/adelle_light-webfont.woff2) format("woff2"),
    url(./fonts/adelle/adelle_light-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle-regular";
  src: url(./fonts/adelle/adelle_reg-webfont.woff2) format("woff2"),
    url(./fonts/adelle/adelle_reg-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle-italic";
  src: url(./fonts/adelle/adelle_italic-webfont.woff2) format("woff2"),
    url(./fonts/adelle/adelle_italic-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle-bold";
  src: url(./fonts/adelle/adelle_bold-webfont.woff2) format("woff2"),
    url(./fonts/adelle/adelle_bold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle-extrabold";
  src: url(./fonts/adelle/adelle_extrabold-webfont.woff2) format("woff2"),
    url(./fonts/adelle/adelle_extrabold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Adelle Sans */
@font-face {
  font-family: "adelle_sans-light";
  src: url(./fonts/adelle_sans/adelle_sans_light-webfont.woff2) format("woff2"),
    url(./fonts/adelle_sans/adelle_sans_light-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle_sans-regular";
  src: url(./fonts/adelle_sans/adelle_sans_reg-webfont.woff2) format("woff2"),
    url(./fonts/adelle_sans/adelle_sans_reg-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle_sans-italic";
  src: url(./fonts/adelle_sans/adelle_sans_italic-webfont.woff2) format("woff2"),
    url(./fonts/adelle_sans/adelle_sans_italic-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle_sans-bold";
  src: url(./fonts/adelle_sans/adelle_sans_bold-webfont.woff2) format("woff2"),
    url(./fonts/adelle_sans/adelle_sans_bold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "adelle_sans-extrabold";
  src: url(./fonts/adelle_sans/adelle_sans_extrabold-webfont.woff2)
      format("woff2"),
    url(./fonts/adelle_sans/adelle_sans_extrabold-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Cherry Blossoms */
@font-face {
  font-family: "cherry_blossoms-regular";
  src: url(./fonts/cherry_blossoms/cherry_blossoms_reg-webfont.woff2)
      format("woff2"),
    url(./fonts/cherry_blossoms/cherry_blossoms_reg-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* ######################################################## */

/* Find new color shades: https://www.colorhexa.com/000000-to-1f87b2 */
:root {
  --dst-color-font: #0f0f0f;

  --dst-color-primary-dark: #1c7ca3;
  --dst-color-primary: #1f87b2;
  --dst-color-primary-light: #78b7d1;
  --dst-color-primary-extralight: #d2e7f0;

  --dst-color-secondary: #2b363d;
  --dst-color-secondary-light: #80868b;
  --dst-color-secondary-extralight: #d5d7d8;

  --dst-color-cissp-dark: #004ea9;
  --dst-color-cissp: #0055b8;
  --dst-color-cissp-extralight: #eaf1f9;

  --dst-color-cism-dark: #009fab;
  --dst-color-cism: #00adbb;
  --dst-color-cism-extralight: #eaf8f9;

  --dst-color-sscp-dark: #004f48;
  --dst-color-sscp: #00564e;
  --dst-color-sscp-extralight: #eaf1f0;

  --dst-color-ccsp-dark: #a06b00;
  --dst-color-ccsp: #af7500;
  --dst-color-ccsp-extralight: #f8f4ea;

  --dst-color-ccsk-dark: #ac471f;
  --dst-color-ccsk: #bc4d22;
  --dst-color-ccsk-extralight: #f9f0ed;

  --dst-color-cisa-dark: #0bbbe3;
  --dst-color-cisa: #02aff9;
  --dst-color-cisa-extralight: #e8f3f8fc;

  --dst-color-correct-dark: #2e7d32;
  --dst-color-correct: #408844;
  --dst-color-correct-extralight: #edf7ed;
  --dst-color-incorrect: #cf4229;
  --dst-color-incorrect-extralight: #fbefed;

  --dst-font-body-regular: "adelle_sans-regular";
  --dst-font-header-regular: "adelle-regular";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  color: var(--dst-color-font);
  font-family: "adelle_sans-regular";
  line-height: 1.4;
}

/* ######################################################## */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 32px;
}
h5 {
  font-size: 26px;
}
h6 {
  font-size: 22px;
}

/* ######################################################## */

p,
a {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px; 
}
a,
a:hover,
a:visited,
a:active {
  color: #e20d0c;
  text-decoration: none;
}

/* ######################################################## */

.font-header-light {
  font-family: "adelle-light";
  font-weight: 400;
  color: white;
}
.font-header-regular {
  font-family: "adelle-regular";
}
.font-header-italic {
  font-family: "adelle-italic";
}
.font-header-bold {
  font-family: "adelle-bold";
}
.font-header-extrabold {
  font-family: "adelle-extrabold";
}

.font-body-light {
  font-family: "adelle_sans-light";
}
.font-body-regular {
  font-family: "adelle_sans-regular";
}
.font-body-italic {
  font-family: "adelle_sans-italic";
}
.font-body-bold {
  font-family: "adelle_sans-bold";
}
.font-body-extrabold {
  font-family: "adelle_sans-extrabold";
}

.font-accent {
  font-family: "cherry_blossoms-regular";
  /* the font size of cherry blossoms should almost always be double the surrounding font */
  font-size: 32px;
  line-height: 0.5;
}

.font-color-regular {
  color: var(--dst-color-font);
}

/* ######################################################## */

.hidden {
  display: none;
}
@media screen and (max-width: 520px) {
  h2 {
    font-size: 33px;
  }
}