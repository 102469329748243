.signup-confirm {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.signup-confirm-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    max-width: 340px;
    width: 80%;
}
.text{
    text-align: center !important;
    justify-content: center !important;
}

.signup-confirm-form > * {
    margin: 10px 0 !important;
    width: 100%;
}

.signup-confirm-success {
    text-align: center;
}

.signup-confirm-success > p {
    font-size: 18px;
    margin: 15px 15px 23px 15px;
    width: 90%;
}

@media only screen and (min-width: 600px) {
    .signup-confirm {
        height: 100%;
        margin-top: calc(calc(0px - var(--topbar-height)) - 30px);
    }
}