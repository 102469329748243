:root {
  --width: 100%;
  --height: 534px;
  --top-color: #030202;
  --bottom-color: #fefefe;
  --red-color: #c5abab;
}

/* Gradient background */
.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(to bottom, #ed8191, #f8a5c2, #ffe6e6);
  color: #000000;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Align the back button to the top left */
.back-button-contact {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}

.separator {
  position: relative;
  width: var(--width);
  height: var(--height);
}

/* Styling for TableContainer */
.scrollableTableContainer {
  height: 150px;
  overflow-y: auto;
  background-color: #ffffff; 
  border: 2px solid #75429c; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

/* Styling the table header */
.tableHeaderCell {
  background-color: #75429c !important; 
  color: #ffffff !important; 
  font-weight: bold;
  text-align: left;
  padding: 12px 8px;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 10;
  border: 0;
}

/* Adjust table body rows for better UI */
.MuiTableRow-root {
  background-color: #f8f8f8;
}

.MuiTableRow-root:nth-of-type(odd) {
  background-color: #f2e7f3;
}

.MuiTableRow-root:hover {
  background-color: #f1cce5; /* Add a hover effect on table rows */
  transition: background-color 0.3s ease;
}

.MuiTableCell-root {
  padding: 10px 8px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

.font-size {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}

.contact-us-content {
  max-width: 100%;
  margin-bottom: 20px;
  text-align: center;
  box-sizing: border-box;
}

.contact-us-content h1,
.contact-us-content h2 {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  font-family: "raleway", sans-serif;
  margin-top: 10px;
  text-align: center;
}

.contact-us-content p {
  line-height: 1.4;
  max-width: 100%;
  margin: 10px auto;
  text-align: center;
  font-family: "raleway", sans-serif;
  color: #fff;
  padding-bottom: 10px;
}

.contact-us-content form .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 90%;
}

.contact-us-content form label {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: "raleway", sans-serif;
}

.contact-us-content form select,
.contact-us-content form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #75429c;
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
  font-family: "raleway", sans-serif;
}

.contactus_button {
  background-color: #75429c;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 150px;
  text-align: center;
  letter-spacing: 1px;
  font-size: 18px;
  font-family: "raleway", sans-serif;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.3s ease-in-out;
  align-self: center;
}

/* Mobile-Responsive Styles */

/* Adjust for tablets and smaller screens */
@media (max-width: 768px) {
  .contact-us-content {
    max-width: 90%;
    padding: 0 10px;
  }

  .contact-us-content h1,
  .contact-us-content h2 {
    font-size: 24px;
  }

  .contact-us-content p {
    font-size: 14px;
  }

  .back-button-contact {
    font-size: 20px;
    top: 10px;
    left: 10px;
  }

  .contactus_button {
    width: 130px;
    font-size: 16px;
  }

  .contact-us-content form select,
  .contact-us-content form textarea {
    font-size: 14px;
    width: 100%;
  }
}

/* Adjust for smartphones and smaller devices */
@media (max-width: 480px) {
  .contact-us-content {
    max-width: 100%;
    padding: 0 10px;
  }

  .contact-us-content h1,
  .contact-us-content h2 {
    font-size: 22px;
    margin-top: 5px;
  }

  .contact-us-content p {
    font-size: 12px;
  }

  .back-button-contact {
    font-size: 18px;
    top: 5px;
    left: 5px;
  }

  .contactus_button {
    width: 120px;
    font-size: 14px;
  }

  .contact-us-content form select,
  .contact-us-content form textarea {
    font-size: 12px;
    padding: 8px;
  }
}

/* Adjust for very small devices */
@media (max-width: 321px) {
  .contact-us-content {
    max-width: 300px;
    padding: 0 5px;
  }

  .contact-us-content h1,
  .contact-us-content h2 {
    font-size: 20px;
  }

  .back-button-contact {
    font-size: 16px;
    top: 3px;
    left: 3px;
  }

  .contactus_button {
    width: 110px;
    font-size: 12px;
  }

  .contact-us-content form select,
  .contact-us-content form textarea {
    font-size: 12px;
  }
}
