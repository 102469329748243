
.mainContainerSignup {
  /* width: 100%;
  height: 800px;
  background-image: linear-gradient(to bottom, black, rgb(46, 37, 25));

  background-repeat: no-repeat;
  background-size: cover; */
  /* position: absolute; */
  /* box-sizing: border-box;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px; */
  /* position: fixed; */
}
.mainContainer-sub {
  border: 1px solid #483b3b;
  border-radius: 10px;
  padding: 20px;
  background: linear-gradient(to right, #000000, #340808);
  max-width: 700px;
  max-height: 200%;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Additional styles for larger screens (e.g., tablets and desktops) */
  @media (min-width: 768px) {
    width: 70%;
  }

  /* Additional styles for smaller screens (e.g., mobile phones) */
  @media (max-width: 767px) {
    width: 95%;
    top: 44%;
  }
}

.sub-logoDiv {
  border: 2px solid rgb(234, 84, 84);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 14px;
}
.descrption {
  height: 50px;
  width: 60px;
}
.logoHead1 {
  text-align: center;
  margin: 0px;
  font-family:'Open Sans', sans-serif;
  font-size: 25px;
  color: red;
  font-weight: 800;
  padding-bottom: 4px;
  padding-top: 20px;
  letter-spacing: 5px;
  text-transform: none;
  /* width: 600px; */
}
.logosubHead {
  text-align: center;
  color: white;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 2px;
  font-weight: lighter;
  font-size: 25px;
  margin-top: 25px;
}
.phoneLabel1 {
  color: red;
  font-weight: lighter;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}
.phoneLabel {
  color: red;
  font-weight: lighter;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
}
.phoneDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: white;
  max-width: 325px;

  height: 40px;
  margin-top: 29px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* Additional styling for Google Login button if needed */

.line-container {
  position: relative;
}

.line {
  border: none;
  height: 1px; /* Adjust the thickness of the line as needed */
  background-color: white;
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  margin: 0;
}
.line-1 {
  border: none;
  height: 1px; /* Adjust the thickness of the line as needed */
  background-color:#8f5454;
  position: absolute;
  bottom: 80%;
  left: 5%;
  right: 0;
  margin: 0;
  width: 90%;
}


.codeSelect {
  height: 30px;
  font-size: 15px;
  border: none;
  /* margin-left: 20px; */
  margin-top: 5px;
  width: 30%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  box-sizing: border-box;
}
option {
  height: 100px;
}

.phoneCode {
  font-size: 18px;
}
.phoneInput {
  border: none;
  height: 28px;
  margin-top: 5px;
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 5px;
  width: 130%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-sizing: border-box;
}
.phoneOtp {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 150px;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  border: 2px solid rgb(234, 84, 84);

  font-size: 18px;
  cursor: pointer;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 4px;
  background-color: red;
  color: white;
}
.error1 {
  left: 10%;
  position: relative;
  letter-spacing: 3px;
  color: red;
  margin-top: 15px;
}
.signuperror {
  color: red;
  letter-spacing: 3px;
  margin-top: 15px;
}
.otpInput {
  display: flex;
  height: 60px;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* width: 45%; */

  /* margin-left: 150px; */
}
.otpBox {
  margin-left: 17px;
  margin-top: 5px;
  height: 30px;
  width: 30px;
  font-size: 10px;
  text-align: center;
}
.otp1 {
  align-items: center;
  /* display: flex;
    justify-content: center;
    margin: auto; */
  margin-bottom: 40px;
  margin-top: 35px;
  margin-left: 20px;
  max-width: 12%;
  font-weight: bold;
  height: 30px;
  border-radius: 4px;
  background-color: red;
  color: white;
  font-size: 9px;
  letter-spacing: 6px;
  font-family:'Open Sans', sans-serif;
  cursor: pointer;
}
.errorDiv {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}
