.while-browser-redirect {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    padding: 0 1rem;
    text-align: center;
    width: 100%;
}
.while-browser-redirect * {
    margin-bottom: 2rem;
}
.while-browser-redirect button {
    width: 100%;
    max-width: 20rem;
    background-color: rgb(200, 0, 0);
}
.while-browser-redirect button:hover {
    border-color: rgb(150, 3, 3);
    background-color: rgb(200, 0, 0);
}