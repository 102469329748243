.progress-container {
    text-align: center;
    padding: 50px;
    margin-bottom: 20px;
    
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
       

}

.head {
    font-size: 50px;
    font-family: 'sans-serif';
    margin: 0;
    padding-top: 30px;
    font-weight: 400;
}

.bg1 {
    height: 400px;
    margin-left: 10px;
}

.bg2 {
    height: 40px;
    margin-right: 20px;
    position: relative;  /* Add this */
    top: 15px;  /* Adjust this value to move the logo down */
    margin-top: 0;
    
}
.main-image-container {
    margin-bottom: 20px;
    
}

.bottom-section {
    width: 100%;
}

.head1 {
    font-size: 45px;
    margin-bottom: 10px;
    color:rgb(0, 145, 255);
    font-weight: bold;
    font-family: sans-serif;
}


.para {
    font-size: 27px;
    color:#ae3017 !important;
    font-weight: bold;
    font-family: sans-serif;
    margin-right:600px;
    white-space: nowrap;

    
      
   
}
.top-section {
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; /* Adjust as necessary */
    
}

.background-lines-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #f0f0f0; /* Light gray background */
}

/* Add this to your existing CSS */
.head1 {
    min-height: 1.2em; /* Adjust based on your font size */
}
.star-text {
    position: relative;
    display: inline-block;
    padding: 10px 0;  /* Add some padding to make room for stars */
}

.star-text::before,
.star-text::after,
.star-text > span::before,
.star-text > span::after {
    content: '★';
    position: absolute;
    font-size: 20px;  /* Larger star size */
    animation: twinkle 2s infinite;
}

/* Pink stars */
.star-text::before { color: #ff69b4; top: -10px; left: 5%; }
.star-text::after { color: #ff69b4; bottom: -5px; right: 10%; animation-delay: 0.5s; }
.star-text > span:nth-child(2)::after { color: #ff69b4; top: -15px; right: 20%; animation-delay: 1.5s; }
.star-text > span:nth-child(3)::before { color: #ff69b4; bottom: -10px; left: 25%; animation-delay: 0.7s; }

/* Light purple stars */
.star-text > span:nth-child(7)::before { color: #e6a8d7; top: -5px; right: 5%; animation-delay: 1.7s; }
.star-text > span:nth-child(8)::after { color: #e6a8d7; bottom: -15px; left: 60%; animation-delay: 0.1s; }

@keyframes twinkle {
    0%, 100% { opacity: 0; transform: scale(0.5); }
    50% { opacity: 1; transform: scale(1); }
}

.head1 {
    min-height: 1.2em;
}