.dashboard .MuiTabs-root {
  width: 100%;
  border-bottom: 1px solid var(--dst-color-secondary-extralight);
  max-width: 650px;
  position: sticky;
  top: var(--topbar-height);
  background-color: #fff;
  z-index: 100;
}

.dashboard-tab-content {
  margin-top: 30px;
  width: 100%;
}
