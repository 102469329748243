.page-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    max-width: 600px;
    margin: 0 auto;
}

.page-wrapper-title, .page-wrapper-subtitle {
    text-align: center;
}

.page-wrapper-subtitle {
    font-size: 18px;
    margin-top: 5px;
}

@media only screen and (min-width: 600px) {
    .page-wrapper {
        padding: 25px 20px;
    }
}