
body,
html {
  margin: 0;
  padding: 0;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  /* Ensure no margin */
}

.upperDiv,
.lowerDiv {
  flex: 1;
  border-top-left-radius: 20px;
  /* Rounded top-left corner */
  border-top-right-radius: 20px;
  /* Rounded top-right corner */
  position: relative;
  /* Position relative for absolute positioning of the circular div */
}

.redCircle {
  position: absolute;
  top: -30px;
  /* Adjust the top position */
  left: -25px;
  /* Adjust the left position */
  width: 150px;
  /* Adjust the width */
  height: 150px;
  /* Adjust the height */
  color: white;
  background-color: #e20d0c;
  border-radius: 50%;
  /* Make it a circular div */
}

.lowerDiv .privacypolicy{
  padding-left: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lowerDiv .privacy-check{
  /* padding-left: 3%; */
  margin-right: 4%;  

  display: flex;
  flex-direction: row;
  align-items: center;
}



.backArrow {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 50px;
  margin-left: 50px;
  font-weight: bold;
}

.redCircle h9 {
  margin-top: 50px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 25px;
}

.upperDiv {
  flex: 20%;
  /* Allocate 20% of the height */
}

.lowerDiv {
  flex: 80%;
  /* Allocate 80% of the height */
  background-color: black;
  color: white;
  text-align: center;
  align-items: center;
}

.lowerDiv h5 {
  margin-top: 15px;
}

.lowerDiv p {
  font-size: 12px;
}

form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label {
  margin-right: 10px;
}

input {
  width: 80%;
  /* 80% width for the mobile number input */
}

.phoneDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: white;
  max-width: 325px;

  height: 40px;
  margin-top: 29px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mobile-code-select {
  height: 30px;
  font-size: 15px;
  border: none;
  /* margin-left: 20px; */
  margin-top: 5px;
  width: 42%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  box-sizing: border-box;
  color:black !important;
}

option {
  height: 100px;
}

.login-Link {
  color: #1F87B2;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;

}
.signup-error-text {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  color: #1F87b2 !important;
  font-family: 'Open Sans', sans-serif;
  margin-inline: 2px;
}
.phoneInput {
  border: none;
  height: 28px;
  margin-top: 5px;
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 5px;
  width: 130%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-sizing: border-box;
}

.otpSentAnimation {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  color: #4CAF50; /* Green color */
  font-size: 14px; /* Reduced font size */
  margin-top: 3px; /* Reduced margin-top */
  margin-bottom: 3px; /* Reduced margin-bottom */
  position: relative;
}

@keyframes tickAnimation {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.otpSentAnimation.show::before {
  content: '\2714'; 
  display: inline-block;
  margin-right: 3px; 
  animation: tickAnimation 1.5s ease-in-out; 
}

.lowerDiv .phoneOtp {
  background-color: #ccc;
    color: #999;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    width: 180px;
    text-align: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 16px; 
    font-family:'Open Sans', sans-serif;
    cursor: pointer; 
    transition: transform 1s ease-in-out; 
    font-weight: bold;
}
.phoneOtp.active {
  background-color: #e20d0c;
  color:  #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  width: 180px;
  text-align: center;
  align-items: center;
  letter-spacing: 2px;
  font-size: 16px; 
  font-family:'Open Sans', sans-serif;
  cursor: pointer; 
  transition: transform 1s ease-in-out; 
  font-weight: bold;
}


.otpVerify.active {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 150px;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  border: 2px solid rgb(234, 84, 84);

  font-size: 18px;
  cursor: pointer;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 4px;
  background-color: #e20d0c;
  color: white;
}
.otpVerify {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 150px;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  border: 2px solid rgb(29, 8, 8);

  font-size: 18px;
  cursor: pointer;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 4px;
  background-color:#ccc;
  color: white;
}


.error-position-container {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.mobile-error-message {
  left: 0%;
  position: relative;
  letter-spacing: 3px;
  color: #e20d0c;
  margin-top: 15px;
}

.sign-up-error-message {
  color: #e20d0c;
  letter-spacing: 3px;
  margin-top: 15px;
}

.otp-Input {
  display: flex;
  height: 60px;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* width: 45%; */

  /* margin-left: 150px; */
}

.otp-Box {
  margin-left: 17px;
  margin-top: 10px;
  height: 30px;
  width: 30px;
  font-size: 10px;
  text-align: center;
}

.resendOTPButton {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  /* margin-top: 10px; */
  /* margin-bottom: 30px; */
  width: 120px;
  font-weight: bold;
  height: 20px;
  /* border-radius: 10px; */
  border: 2px solid rgb(0, 0, 0);

  font-size: 10px;
  cursor: pointer;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 4px;
  background-color: #000000;
  color: #10a1f0;
}

.resendMessage button{
  display: inline;
}
.spinner-container {
  display: inline-block;
  font-size: 24px;
  margin-left: 5px;
}


.fa-spinner {
  color: #f7f8f9; 
}

@media screen and (min-width:0px) and (max-width: 321px) {
  .mobile-error-message {
    left: 0%;
    position: relative;
    letter-spacing: 2px;
    color: #e20d0c;
    margin-top: 15px;
  }
  .privacypolicy{
    padding-left: -70px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
}
@media screen and (min-width:321px) and (max-width: 551px) {
  .mobile-error-message {
    left: 0%;
    position: relative;
    letter-spacing: 2px;
    color: #e20d0c;
    margin-top: 15px;
  }
  .error {
    color: red;
  
    text-align: center;
    margin-top: 6px;
    letter-spacing: 3px;
  }
  .lowerDiv .privacy-check{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -20%;
  }
  
  .lowerDiv .privacypolicy{
    padding-left: 0px;
    margin-left: -80px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  

  
}

@media screen and (min-width:1000px) {
  .lowerDiv .privacy-check{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8%;
  }
}