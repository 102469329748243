.maincontainer-user {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, black, rgb(46, 37, 25));
  background-repeat:repeat;
  background-size:auto;
  padding: 0px;
  box-sizing: border-box;
}
.login {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: linear-gradient(to bottom, black, rgb(137, 6, 13));
}
/* .logoDiv {
  border: 2px solid rgb(234, 84, 84);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
} */
.descrption {
  height: 50px;
  width: 60px;
}
body.modal-open {
  overflow: hidden;
}

.maincontainer-user.modal-open {
  opacity: 0.5%;
}
.login-card {
  border: 1px solid #483B3B;
  border-radius: 10px;
  background: linear-gradient(to right, #000000, #340808);
  max-width: 700px;
  max-height: 650px;
  /* margin: auto; */
  position: absolute;
  top: 54%;
  left: 48%;
  transform: translate(-50%, -50%);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-repeat: no-repeat;
  /* Additional styles for larger screens (e.g., tablets and desktops) */
  @media (min-width: 768px) {
    width: 70%;
    top:50%;
  }
  @media (max-width: 2400px) {
    width: 70%;
    top: 59%;
  }
  /* Additional styles for smaller screens (e.g., mobile phones) */
  @media (max-width: 669px) {
    width: 95%;
    top: 55%;
}


.scrollable-content::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: black; /* Set the color of the scrollbar thumb */
}

}
.or-text-position {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
}
.or-text-position .line {
  flex-grow: 1;
  height: 1px;
  background-color: white;
  margin: 0 10px;
}
.text-color {
  color: rgb(246, 244, 244);
}
/* .otpField {
  margin-right: 100px;
  display: flex;
  text-align: center;
}
.otp2 {
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;
  margin-left: 20px;
  max-width: 20%;
  font-weight: bold;
  height: 30px;
  border-radius: 4px;
  background-color: red;
  color: white;
  font-size: 9px;
  letter-spacing: 6px;
  font-family: "Simply square JL V1";
  cursor: pointer;
} */
.font-header-light-login{
  padding-top: 2vh;
  font-family: 'Open Sans';
  color:white;
  font-size: 37px;
  font-weight: lighter;
  margin-bottom: 5%;
}
.errorgreen1 {
  color: green;
  text-align: center;
  margin-top: -30px;
  letter-spacing: 3px;
}
@media only screen and (max-width: 80vw) {
  .login {
    height: 100%;
    margin-top: calc(calc(0px - var(--topbar-height)) - 30px);
  }
}
/* .valid-otp {
  color: green;
  text-align: center;
  margin-top: -30px;
  letter-spacing: 3px;
} */
/* .otpField {
  margin-right: 100px;
  display: flex;
  text-align: center;
  flex-direction: column; } */
/* .otp2 {
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 250px;
  max-width: 20%;
  font-weight: bold;
  height: 30px;
  border-radius: 4px;
  background-color: red;
  color: white;
  font-size: 9px;
  letter-spacing: 6px;
  font-family: "Simply square JL V1";
  cursor: pointer;
} */
/* .valid-otp {
  color: green;
  text-align: center;
  margin-top: 10px;
  letter-spacing: 3px;
} */
.otpField {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 500px;
  margin-top: 20px; /* Adjust top margin as needed */
}
.otp2 {
  /* Remove margin styles for better centering */
  font-weight: bold;
  height: 30px;
  border-radius: 4px;
  background-color: red;
  color: white;
  font-size: 9px;
  letter-spacing: 6px;
  font-family: 'Open Sans';
  cursor: pointer;
  margin-top: 20px; /* Add top margin for spacing */
}
.valid-otp {
  color: green;
  text-align: center;
  margin-top: 10px; /* Add top margin for spacing */
  letter-spacing: 3px;
}
.mobileview{
  align-items: center;
  display: flex;
  flex-direction:row;
  justify-content: center;
}