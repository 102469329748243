:root {
    --width: 100%;
    --height: 590px;
    --top-color: #030202;
    --bottom-color: #fefefe;
    --red-color: #e20d0c;
    /* Red color for the band */
  }
  
  .separator {
    position: relative;
    width: var(--width);
    height: var(--height);
  }
  
  .separator::after {
    position: absolute;
    content: "Hi there";
    right: 0%;
    width: 455%;
    height: 120%;
    /* Adjust the height of the black band */
    background-color: var(--top-color);
    clip-path: polygon(140% 0, 0 0, 0 130%);
  }
  
  .separator::before {
    position: absolute;
    content: "";
    right: 0%;
    width: 250%;
    height: 60%;
    /* Adjust the height of the red band */
    background-color: #e20d0c;
    clip-path: polygon(140% 0, 0 0, 0 170%);
    top: 20%;
    /* Adjust the position to be right below the black band */
  }
  
  .body .title h2 {
    position: relative;
    color: #ffffff;
    margin-top: -413px;
    /* margin-left: 20px; */
    text-align: center;
    font-size: 25px;
    padding-left: 20px;
    font-weight: bold;
  
    padding-right: 20px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 2px;
  
  }
  
  .policy p {
    text-align: center;
    position: relative;
    margin-top: 13%;
  }
  
  
  .button-container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .button-container-login button {
    margin-top: 220px;
  
  }
  
  .button-container-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .button-container-signup button {
    margin-top: 20px;
  
  }
  
  .rounded-button-login {
    background-color: #e20d0c;
    color: #fff;
    padding: 10px 20px;
    /* margin-top: 20px; */
    border: none;
    border-radius: 20px;
    width: 180px;
    text-align: center;
    align-items: center;
    /* margin: 5px; */
    letter-spacing: 2px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    transform: translateY(50px);
    /* Initially move down */
    transition: transform 1s ease-in-out;
    /* Add transition */
    font-weight: bold;
  
  }
  
  .rounded-button-signup {
    background-color: #000000;
    width: 180px;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    margin: 10px;
    margin-top: 50px;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    transform: translateY(50px);
    /* Initially move down */
    transition: transform 1s ease-in-out;
    /* Add transition */
  }
  
  .rounded-button:hover {
    background-color: #2980b9;
  }
  
  .overlay-popup {
    position: relative;
  }
  
  
  .modal .close-button {
    position: absolute;
    top: 2px;
    right: -4px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
    padding: 0px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .modal .countdown {
    margin-left: 5px;
    font-size: 14px;
  }
  
  .modal .visible {
    display: flex;
    flex-direction: column;
  }
  .terms-position{
    margin-top: 60px !important;
  }