
.Main-container-profile-user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: rgb(242 236 236);
  background-size: cover;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
}
.css-165mck1{
  position: initial!important;
}
.user-card-container {
  display: flex;
  align-items: center;
  border-radius: 12px;
  max-width: 400px;
  margin-bottom: 3px;
}
.profile-pic {
  width: 30px;
  height: 30px;
  color: #e20d0c;
  margin-left: 100px;
  border-radius: 24px;
}
.profile-pics {
  width: 30px;
  height: 30px;
  color: #e20d0c;
  margin-left: 100px;
  border-radius: 24px;
}
.dob-input-heading {
  background-color: white;
  width: 200px;
  height: 30px;
  margin-left: 49px;
  padding: 5px;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 8px;
}

.profile-container {
  max-width: 375px; /* You can set this to the width you desire */
  margin: auto;
}
.profile-top {
  background: linear-gradient(130deg, #ff7e5f, #feb47b);
  text-align: center;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  color: #fff;
}  

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid white;
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
.birthplaceInput--Container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 400px; 
  height: 30px;
  padding: 1px;
  color: rgb(0, 0, 0);
  border-radius: 8px;
}
.timeofbirth--Container{
  display: flex;
  align-items: center;
  width: 260px;
  color: rgb(0, 0, 0);
  border-radius: 8px;
}
@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
.details-save-button {
    background-color: #ccc;
    color: #999;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    width: 180px;
    text-align: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 16px; 
    font-family:'Open Sans', sans-serif;
    cursor: pointer; 
    transition: transform 1s ease-in-out; 
    font-weight: bold;
}

.profile-top-section {
  background: linear-gradient(to bottom, #000000, #d50606);
  padding: 20px;
  width: 100%;
  margin-top: 5px;
  border-radius: 20px 20px 0 0;
  color: white;
}

.profile-image-container {
  margin: 0 auto;
  width: 120px; 
  height: 120px; 
  bottom: 45px;
  border-radius: 60px; 
  position: relative;
  border: 2px solid white; 
}


.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.profile-user-info {
  margin-top: 10px;
}
.profile-pic-container img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 10px;
}  
.email {
  color: #f3f3f3;
  font-size: 0.9rem;
}
.list-container {
  list-style-type: none;
  padding: 5px;
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  position: initial !important; /* Override the position property */
}
.user-designation {
  font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #594d6d;
    margin: 0px;
    width: 360px ; 
    position: initial;
  }
  .edit-icon {
    cursor: pointer;
    margin-left: 5px;
  }
  .edit-icon2 {
    cursor: pointer;
    margin-left: 5px;
  }
.user-name {
  font-size:18px;
  color: #12022f;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 2px;
}
.account-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.account-info-title {
  font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #12022f;
    margin-bottom: 5px;
}

.info-field {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  margin-top: 10px;
  flex-direction: column;
}


.info-field svg {
  min-width: 30px; /* FontAwesome icon size */
  color: #ff7e5f;
}

.field-name {
  font-weight: bold;
  margin: 0 10px;
  flex: 1;
} 

.field-value {
  text-align: right;
  color: #666;
} 

.username {
  font-size: 18px; /* Adjust size as needed */
  font-weight: bold;
  text-align: center;
  margin-top: -50px;
  font-family: 'Open Sans', sans-serif;
  color: #fef8f8;
}

.user-email {
  font-size: 15px; 
  color:  #fff;
  margin-bottom:5px ;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}
.back-to-home-button {
  color: white;
  font-size: 18px; 
  border: none; 
  cursor: pointer; 
}

.back-to-home-button i {
  margin-right: 10px;
  color: white; /* Adds spacing between icon and text */
}

.back_button {
  margin-top: -20px;
  margin-bottom: 10px;
  padding: 6px;
  width: 20%;
  border-radius: 20px;
  border: 1px solid white;
  background:  #000000;
  color: white;
  cursor: pointer;
}

.details-save-button.active {
  background-color: #e20d0c;
  color:  #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  width: 180px;
  text-align: center;
  align-items: center;
  letter-spacing: 2px;
  font-size: 16px; 
  font-family:'Open Sans', sans-serif;
  cursor: pointer; 
  transition: transform 1s ease-in-out; 
  font-weight: bold;
}
.details-save-button.active .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-input-text,
.mobile-input-text {
  font-weight: bold;
}

.input-lob-details input {
  font-family: 'Open Sans', sans-serif;
  font-size: 19px; 
  padding: 8px; 
  color: #111111; 
  font-weight: bold;
}
.home-page-back-button{
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 21px;
  margin-left: 20px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  align-self: flex-start;
}
.save-button:hover {
  background-color: #F53F23; 
}
.input-lob-heading {
  color:white !important;
  font-size: 20px;
  margin-bottom: 10px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
}
.input-lob-details-heading {
  color:white !important;
  font-size: 20px;
  margin-bottom: 10px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 10px;

}
.input-dob-details {
  color:white !important;
  font-size: 20px;
  margin-bottom: 10px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
}
.input-tob-details-heading {
  color:white !important;
  font-size: 20px;
  margin-bottom: 16px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
}
.date-Input{
  background-color: white;
  width: 160px;
  height: 40px;
}
.mobile-input-text{
  margin-left: 19%;
  height: 32px;
  border-radius: 6px;
  width: 200px;
}
.email-input-text{
  margin-left: 27%;
  height: 32px;
  border-radius: 6px;
  width: 200px;
}
.name--Input{
  height: 30px;
  border-radius: 8px;
  width: 400px;
  background-color:white ;
  font-weight: bold;
  font-family:'Open Sans', sans-serif;
}
.row-position{
  display: flex;
  flex-direction: row;
}
.error-message-display{
  font-size: 12px;
  color: red;
  margin-left: 2px;
  font-family:'Open Sans', sans-serif;
}
.birthplaceInput-Container {
  position:static;
}
.birthplaceInput-Container {
  flex: 1;
  height: 30px;
  padding: 1px;
  border: 1px solid white;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 9px;
  /* width: 10px; */
}
.birthplaceInputContainer input{
  padding: 5px; 
  /* margin-left: -50px; */
  width: 100%;
  right: 0%;
  border: 1px solid white;
  background-color: white;
  color: rgb(0, 0, 0);
}
.form-Row {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.suggestion--popup {
  background-color: white;
  border: 1px solid #ccc;
  position: absolute;
  margin-top: 187px !important;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}
.signout-button{  
  background-color: black;
  color:  #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  width: 180px;
  text-align: center;
  align-items: center;
  letter-spacing: 2px;
  font-size: 16px; 
  font-family:'Open Sans', sans-serif;
  cursor: pointer; 
  font-weight: bold;}

  .fullscreen-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .close-account-button {  
    background-color: F53F23;
    color:  #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    width: 180px;
    text-align: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 16px; 
    font-family:'Open Sans', sans-serif;
    cursor: pointer; 
    font-weight: bold;}
  .warning-message{  
    letter-spacing: 1px;
    font-size: 16px; 
    color: #f3f3f3;
    font-family:'Open Sans', sans-serif;
    font-weight: bold;
  }

  .warning-message-buttons-container{
      display: flex;
      justify-content: space-evenly
    }

  .warning-message-button{  
    background-color: black;
    width: 120px;
    font-family:'Open Sans', sans-serif;
    cursor: pointer; 
    font-weight: bold;}
  
    .fullscreen-popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    /* Content inside the popup */
    .popup-content {
      background-color: #75429c; 
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
      width: 80%;
      max-width: 400px;
      text-align: center;
      animation: popupAnimation 0.5s ease-in-out; 
    }
    
    /* Styling the success message text */
    .success-message {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff; /* Success green color */
      margin: 0;
      padding: 10px 0;
      font-family:'Open Sans', sans-serif;
    }
    
    /* Animation for popup content */
    @keyframes popupAnimation {
      from {
        transform: scale(0.8); /* Start slightly smaller */
        opacity: 0; /* Start invisible */
      }
      to {
        transform: scale(1); /* End at normal size */
        opacity: 1; /* Fully visible */
      }
    }
  .styled--Input {
    font-family:'Open Sans', sans-serif;
    border: 0px solid black !important;
    background-color: white;
    color:black !important;
    border-radius: 9px;
    height:56px !important;
    font-weight: 600 !important;
  }
  .profile-footer-social-media-links {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  
  .profile-footer-social-media-links a {
    font-size: 30px;
  }
  .profile-footer-social-media-links img.twitter-logo {
    width: 30px;
    height: auto;
    color: #157CEA;
    margin-bottom: 4px;
  }
  
  .profile-footer-text-position{
    margin-top: 20px;
    margin-left: 0% !important;
    margin-bottom: 10px;
    font-family:'Open Sans', sans-serif;
    font-weight: bold;
    text-align: justify;
  }
@media screen and (min-width: 310px) and (max-width: 360px) {
.name--Input { 
  width: 200px;
}

.popup-content {
  width: 95%;
}

.birthplaceInput--Container {
  width: 200px;
}
.account-info-title {
  margin-right: 80px;
}
.list-container {
  margin-right: 80px;
}
.email-input-heading {
  margin-left: -123px;
}
.input-mobile-details1 {
  margin-left: -150px;
}

.input-heading {
  width: 170px;
  margin-left: 37px; 
}
.dob-input-heading{
  width: 170px;
  margin-left: 34px; 
}
.black-background-container {
  width: 305px;
  height: 220px;
}
.email-input-text {
  width: 180px;
}
.mobile-input-text {
  width: 182px;
  margin-left:7px;
}
.styled--Input {
  font-family:'Open Sans', sans-serif;
  border: 0px solid black !important;
  background-color: white;
  color:black !important;
  border-radius: 9px;
  height:56px !important;
  margin-right: -141px !important;
  font-weight: 600 !important;
}
.email-input-text{
  margin-left: 13%;
  height: 32px;
  border-radius: 6px;
  width: 180px;
}
.profile-footer-text-position{
  margin-left: 6% !important;
  margin-bottom: 10px;
  font-family:'Open Sans', sans-serif;
  font-weight: bold;
  text-align: justify;
}
.mobile-input-heading {
  color:rgb(22, 20, 20);
  font-size: 20px;
  margin-bottom: 10px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: -123px;
  font-weight: bold;
}
.home-page-back-button{
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 21px;
  margin-left: 10px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  align-self: flex-start;
}

}

/* .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 0.85em;
  overflow: visible;
  vertical-align: -0.125em;
} */

@media screen and (min-width: 361px) and (max-width:551px) {
  .home-page-back-button{
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 21px;
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    align-self: flex-start;
  }
  .popup-content {
    width: 95%;
  }
  .name--Input { 
    width: 220px;
  }
  .account-info-title {
    margin-right: 90px;
  }
  .list-container {
    margin-right: 90px;
  }
}

@media (max-width: 768px) {
  .suggestion--popup {
    width: 50%;
    left: 0%;
    top: calc(60% + 0px); 
  }
  .user-designation {
    width: 300px;
  }
  .suggestion--popup {
  margin-top: 284px !important;
  width: 330px !important;
  margin-left: 5px;
  }
  .back_button {
    width: 60%;
  }
  .user-name {
    font-size: 18px;
  }
  .birthplaceInput--Container {
    width: 200px;
  }
  .Main-container-profile-user-details {
    margin-bottom: 55px;
  }
  .account-info-title {
    margin-right: 150px;
  }
  .list-container {
    margin-right: 100px;
  }
  .profile-footer-text-position{
    margin-left: 4% !important;
    margin-bottom: 5px;
    font-family:'Open Sans', sans-serif;
    font-weight: bold;
    text-align: justify;
    margin-right: 16px !important;
  }

}