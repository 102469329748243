

Body, html {
  margin: 0;
  padding: 0;
}
.required {
  color: red;
  margin-left: 4px;
}

.Body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}

.upperDiv {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redCircle {
  position: absolute;
  top: -30px;
  left: -25px;
  width: 150px;
  height: 150px;
  color: white;
  background-color: red;
  border-radius: 50%;
}

.backArrow {
  font-size: 23px;
  margin-bottom: 5px;
  margin-top: 50px;
  margin-left: 50px;
  font-weight: bold;
  cursor: pointer;
}

.signup-lowerDiv {
  margin-top: auto;
  flex: 59%;
  color: white;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  animation: fadeIn 1.5s ease-in-out;
  align-items: center;
  justify-content: center;
}

.lowerDiv h5 {
  margin-top: 15px;
  margin-bottom: 20px;
  animation: slideIn 1s ease-out;
}

.lowerDiv p {
  font-size: 12px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideUp 1s ease-out;
}



input {
  width: 80%;
}

.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%; 
  position: relative;
}

label {
  display: inline-flex;
  width: 150px;
  margin-right: -8px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
}

.styledInput, .gender-styledInput, .birthPlaceStyledInput {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
  color: rgb(73, 68, 68 / 80%);
  border-radius: 9px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.styledInput:focus, .gender-styledInput:focus, .birthPlaceStyledInput:focus {
  border-color: #ff6347;
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.5);
}



.birthPlaceStyledInput {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.suggestion-popup {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  color: #000000;
}

.suggestion-popup div {
  padding: 10px;
  cursor: pointer;
}

.suggestion-popup div:hover {
  background-color: #f0f0f0;
}

.transparentForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slideUp 1s ease-out;
  background: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40%; /* Adjusted to center the form */
  margin: 0 auto;
  margin-bottom: 50px; /* Center the form */
}

.input-icon{
  padding-right: 10px;
}

button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 6px;
  border-radius: 20px;
  border: 1px solid white;
  background-color: rgb(200, 0, 0);
  color: white;
  cursor: pointer;
}




button:active {
  transform: scale(0.95);
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}



.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid white;
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.Submit-Button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 100px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid white;
  background-color: rgb(200, 0, 0);
  color: white;
  cursor: pointer;
  width: 200px;
  height: 40px;
  transition: background-color 0.3s, transform 0.3s;
}

.Submit-Button:hover {
  background-color: #ff4500;
  transform: scale(1.05);
}

.Submit-Button:active {
  transform: scale(0.95);
}
.formRow {
  margin-bottom: 15px;
}


.email-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

}

.error-messager{
  color: red;
  font-size: 14px !important;
  margin-left :15px;
  font-weight: 100;
  white-space: nowrap;
  margin-top:20px;
  
}



@media (max-width: 890px) {
  
  .transparentForm {
    width: 90%;
    padding: 15px;
    margin-top: 100px;
  }

  .redCircle {
    width: 140px;
    height: 140px;
    top: -25px;
    left: -15px;
    top: -30px;

  }

  .backArrow {
    font-size: 22px;
    margin-top: 55px;
    margin-left: 40px;
  }

  h2.user-info {
    font-size: 24px;
  }

  .styledInput, .gender-styledInput, .birthPlaceStyledInput {
    max-width: 100%;
    width: 100%; /* Ensure all inputs take full width */
    padding: 8px;
  }
  

  .formRow {
    flex-direction: column;
    align-items: flex-start; 
    width: 100%; 
  }

  label {
    width: 100%;
    margin-bottom: 5px;
  }


  .Submit-Button {
    margin: auto;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid white;
    background-color: rgb(200, 0, 0);
    color: white;
    cursor: pointer;
    width: 200px;
    height: 40px;
    transition: background-color 0.3s, transform 0.3s;
  }

}
.error-messager {
  margin-top: 1px;
 
}



@media (max-width: 480px) {
  .backArrow {
    font-size: 23px; /* Further reduced text size */
    margin-top: 43px;
    margin-left: 30px;
  }

  .upperDiv {
    flex: 15%;
  }

  .signup-lowerDiv {
    flex: 65%;
    padding: 15px;
  }

  .transparentForm {
    width: 90%;
    padding: 10px;
  }

  .styledInput, .gender-styledInput, .birthPlaceStyledInput {
    max-width: 100%;
    width: 100%; /* Ensure all inputs take full width */
    padding: 8px;
    font-size: 14px; /* Reduced text size for inputs */
  }

  .formRow {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

}
