

/* .mainContainerSignup {
  width: 100%;
  height: 800px;
  background-image: linear-gradient(to bottom, black, rgb(46, 37, 25));

  background-repeat: no-repeat;
  background-size: cover; 
  position: absolute; 
  box-sizing: border-box;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
  position: fixed;
} */
.mainContainer-Signup {
  width: 100%;
  height: 800px;
  background-color: white;
  /* background-image: linear-gradient(to bottom, black, rgb(46, 37, 25)); */

  background-repeat: no-repeat;
  background-size: cover; 
  position: absolute; 
  box-sizing: border-box;
  /* padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
  position: fixed; */
}
.main-Container-sub {
  border: 1px solid #483b3b;
  border-radius: 10px;
  padding: 20px;
  /* background-image: linear-gradient(to right, #000000, #340808); */
  background-color: black;
  max-width: 700px;
  max-height: 200%;
  margin: auto;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Additional styles for larger screens (e.g., tablets and desktops) */
  @media (min-width: 768px) {
    width: 70%;
  }

  /* Additional styles for smaller screens (e.g., mobile phones) */
  @media (max-width: 767px) {
    /* width: 95%;
    top: 44%; */
  }
}

.sub-logoDiv {
  border: 2px solid rgb(234, 84, 84);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 14px;
}
.agree-checkbox {
  margin-top: 10px;
}

.privacy-heading{
  margin-bottom: 2%;
  color: white;
  font-size: 32px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 5px;
  margin-left: 5%;
}
.popup-sub-heading{ 
  color: white;
  font-size: 16px !important;
  font-family:'Open Sans', sans-serif;
  margin-left: 5%;}
.sub-text{ 
  color: white;
  font-size: 16px !important;
  font-family:'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  margin-left: 5% !important;
  /* max-width: 10% !important; */
}

.label-box{
  color: white;
  font-size: 16px;
  font-family:'Open Sans', sans-serif;
  margin-left: -37%;
  width: 55%;
}
.privacy-checkbox{
  margin-left: -10%;
}
.privacy-error-message-display{
  font-size:15px !important ;
  font-family:'Open Sans', sans-serif;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;

}

.close {
  cursor: pointer;
  position: absolute;
  right: -2px;
  top: -2px;
  width: 30px;
  height: 30px;
  background: #000000;
  color:white;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}
.close:hover,
.close:focus {
  color: rgb(229, 8, 8);
  text-decoration: none;
  cursor: pointer;
}

.privacy-logo {
  margin-top: 7%;
  max-width: 150px;
  max-height: 100px;
  margin-bottom: 20px;
}

.privacy-policy-link {
  color: #1F87b2; 
  cursor: pointer;
  margin-left:6px;
  font-weight: bold;
}
.custom-modall {
  display: block;
  position: fixed;
  width: 700px;
  height: 340px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50%);
 background-color: black;
}
.scrollable-content {
  background-color: black;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: black; 
  scrollbar-width: thin;
}
.privacy-policy-link:hover {
  color: #1F87b2; 
}


.popup input[type="checkbox"] {
  margin-top: 10px;
}
.privacy-matter-text{
  display: flex;
  justify-content: center;
  font-size:16px !important;
  font-family:'Open Sans', sans-serif;
  margin-bottom: 5%;
  color: #ffffff;

}
.descrption {
  height: 50px;
  width: 60px;
}
.Success-message {
  text-align: center;
  margin: 0px;
  font-family:'Open Sans', sans-serif;
  font-size: 25px;
  color: rgb(0, 255, 21);
  font-weight: 600;
  padding-bottom: 4px;
  padding-top: 20px;
  letter-spacing: 5px;
  text-transform: none;
  /* width: 600px; */
}
.logo-position{
  display: flex;
  justify-content: center;
}
.congratulations-message-text {
  text-align: center;
  color: white;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 2px;
  font-weight: lighter;
  font-size: 25px;
  margin-top: 25px;
}
.kindly-login-text {
  text-align: center;
  color: white;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 2px;
  font-weight: lighter;
  font-size: 25px;
  margin-top: 25px;
}
.phoneLabel1 {
  color: red;
  font-weight: lighter;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}
.phoneLabel {
  color: red;
  font-weight: lighter;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 20px;
}
.phoneDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: white;
  max-width: 325px;

  height: 40px;
  margin-top: 29px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* Additional styling for Google Login button if needed */

.line-container {
  position: relative;
}

.line {
  border: none;
  height: 1px; /* Adjust the thickness of the line as needed */
  background-color: white;
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  margin: 0;
}
.line-1 {
  border: none;
  height: 1px; /* Adjust the thickness of the line as needed */
  background-color:#8f5454;
  position: absolute;
  bottom: 80%;
  left: 5%;
  right: 0;
  margin: 0;
  width: 90%;
}


.codeSelect {
  height: 30px;
  font-size: 15px;
  border: none;
  /* margin-left: 20px; */
  margin-top: 5px;
  width: 36%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  box-sizing: border-box;
}
option {
  height: 100px;
}

.phoneCode {
  font-size: 18px;
}
.phoneInput {
  border: none;
  height: 28px;
  margin-top: 5px;
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 5px;
  width: 130%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-sizing: border-box;
}
.login-button {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 150px;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  border: 2px solid rgb(234, 84, 84);

  font-size: 18px;
  cursor: pointer;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 4px;
  background-color: red;
  color: white;
}
.error1 {
  left: 8%;
  position: relative;
  letter-spacing: 2px;
  color: red;
  margin-top: 15px;
}
.signuperror {
  color: red;
  letter-spacing: 3px;
  margin-top: 15px;
}
.otpInput {
  display: flex;
  height: 60px;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* width: 45%; */

  /* margin-left: 150px; */
}
.otpBox {
  margin-left: 17px;
  margin-top: 5px;
  height: 30px;
  width: 30px;
  font-size: 10px;
  text-align: center;
}
.otp1 {
  align-items: center;
  /* display: flex;
    justify-content: center;
    margin: auto; */
  margin-bottom: 40px;
  margin-top: 35px;
  margin-left: 20px;
  max-width: 19%;
  font-weight: bold;
  height: 30px;
  border-radius: 4px;
  background-color: red;
  color: white;
  font-size: 9px;
  letter-spacing: 6px;
  font-family:'Open Sans', sans-serif;
  cursor: pointer;
}
.errorDiv {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

@media screen and (min-width: 321px) and (max-width: 551px){


.privacy-checkbox{
  margin-left: -32%;
}
.label-box{
  color: white;
  font-size: 13px;
  font-family:'Open Sans', sans-serif;
  margin-left: -36%;
  width: 53%;
}
.sub-logoDiv {
  border: 2px solid rgb(234, 84, 84);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 14px;
}
.privacy-heading{
  margin-bottom: 2%;
  color: white;
  font-size: 24px;
  font-family:'Open Sans', sans-serif;
  letter-spacing: 2px;
  margin-left: 5%;
}
.para-heading{ 
  color: white;
  font-size: 16px !important;
  font-family:'Open Sans', sans-serif;
  margin-left: 5%;}
.para-1{ color: white;
  font-size: 16px !important;
  font-family:'Open Sans', sans-serif;
  font-weight: 400;
  margin-left: 5%;}
  
.scrollable-content {
  background-color: black;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: black; 
  scrollbar-width: thin;
  }
.privacy-policy-link:hover {
  color: #1F87b2; 
}
.privacy-logo {
  margin-top: 7%;
  max-width: 150px;
  max-height: 100px;
  margin-bottom: 20px;
}

.privacy-policy-link {
  color: #1F87b2; 
  cursor: pointer;
  margin-left:6px;
  font-weight: bold;
}
.custom-modall {
  display: block;
  position: fixed;
  width: 370px;
  max-height: 550px;
  overflow-y: auto;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50%);
 background-color: black;
}
.logo-place{
  display: flex;
  justify-content: center;
}
.close {
  cursor: pointer;
  position: absolute;
  right: -2px;
  top: -2px;
  width: 30px;
  height: 30px;
  background: #000000;
  color:white;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}
.close:hover,
.close:focus {
  color: rgb(229, 8, 8);
  text-decoration: none;
  cursor: pointer;
}
.privacy-matter-text{
  display: flex;
  justify-content: center;
  font-size:16px !important;
  font-family:'Open Sans', sans-serif;
  margin-bottom: 5%;
  color: #ffffff;

}
}

@media screen and (min-width: 0px) and (max-width: 321px){


  .privacy-checkbox{
    margin-left: -66%;
  }
  .label-box{
    color: white;
    font-size: 13px;
    font-family:'Open Sans', sans-serif;
    margin-left: -36%;
    width: 53%;
  }
  .sub-logoDiv {
    border: 2px solid rgb(234, 84, 84);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background-color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 14px;
  }
  .privacy-heading{
    margin-bottom: 2%;
    color: white;
    font-size: 24px;
    font-family:'Open Sans', sans-serif;
    letter-spacing: 2px;
    margin-left: 5%;
  }
  .para-heading{ 
    color: white;
    font-size: 16px !important;
    font-family:'Open Sans', sans-serif;
    margin-left: 5%;}
  .para-1{ color: white;
    font-size: 16px !important;
    font-family:'Open Sans', sans-serif;
    font-weight: 400;
    margin-left: 5%;}
    
  .scrollable-content {
    background-color: black;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: black; 
    scrollbar-width: thin;
    }
  .privacy-policy-link:hover {
    color: #1F87b2; 
  }
  .privacy-logo {
    margin-top: 7%;
    max-width: 150px;
    max-height: 100px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-link {
    color: #1F87b2; 
    cursor: pointer;
    margin-left:6px;
    font-weight: bold;
  }
  .custom-modall {
    display: block;
    position: fixed;
    width: 321px;
    max-height: 550px;
    overflow-y: auto;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50%);
   background-color: black;
  }
  .logo-place{
    display: flex;
    justify-content: center;
  }
  .close {
    cursor: pointer;
    position: absolute;
    right: -2px;
    top: -2px;
    width: 30px;
    height: 30px;
    background: #000000;
    color:white;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
  }
  .close:hover,
  .close:focus {
    color: rgb(229, 8, 8);
    text-decoration: none;
    cursor: pointer;
  }
  .privacy-matter-text{
    display: flex;
    justify-content: center;
    font-size:15px !important;
    font-family:'Open Sans', sans-serif;
    margin-bottom: 5%;
    color: #ffffff;
  
  }
  .error1 {
    left: 8%;
    position: relative;
    letter-spacing: 2px;
    color: red;
    margin-top: 15px;
    margin-right: 35px;
  }
  }