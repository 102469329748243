.reset-password-initiate {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.reset-password-initiate-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    max-width: 340px;
    width: 80%;
}

.reset-password-initiate-form > * {
    margin: 10px 0 !important;
    width: 100%;
}

.reset-password-initiate-success {
    text-align: center;
}

.reset-password-initiate-success > p {
    margin-top: 15px;
    width: 90%;
}

@media only screen and (min-width: 600px) {
    .reset-password-initiate {
        height: 100%;
        margin-top: calc(calc(0px - var(--topbar-height)) - 30px);
    }
}