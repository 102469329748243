.hide-home-icon {
    display: none;
  }
  
  .home-icon-gif-container {
    display: none;
  }
  
  .show-home-icon-gif-container {
    display: block;
  }
  
  .home-icon-size-gif {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 768px) {
    .hide-home-icon {
      display: none;
    }
    
    .home-icon-gif-container {
      display: none;
    }
    
    .show-home-icon-gif-container {
      display: block;
    }
    
    .home-icon-size-gif {
      width: 30px;
      height: 30px;
    }
  }
    